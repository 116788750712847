$vertical-spacing-xxs: 5px;
$vertical-spacing-xs: 8px;
$vertical-spacing-sm: 14px;
$vertical-spacing-md: 25px;
$vertical-spacing-lg: 35px;
$vertical-spacing-xl: 60px;
$vertical-spacing-xxl: 90px;

$horizontal-spacing-xxs: 5px;
$horizontal-spacing-xs: 10px;
$horizontal-spacing-sm: 15px;
$horizontal-spacing-md: 40px;
$horizontal-spacing-lg: 50px;
$horizontal-spacing-xl: 70px;
