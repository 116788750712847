@import "src/style/variable";

.container {
  display: flex;
  justify-content: center;
}

.errorContainer{
  border-radius: 10px;
  background-color: $danger-zone-color-light;
  display: flex;
  align-items: center;
  padding: 10px 50px 10px 20px;
}

.errorIcon {
  color: $danger-zone-color;
  margin-right: 10px;
}
