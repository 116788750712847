@import "src/style/variable";
@import "src/style/mixins";

.coacheeNeedsComponent {
  max-width: 380px;
  margin: 0 auto;

  .firstRow {
    display: flex;
    justify-content: space-between;
  }

  .secondRow {
    @include center-child()
  }
}

@media (max-width: $breakpoint-sm) {
  .coacheeNeedsComponent {
    padding-top: 30px;
    max-width: 300px;
  }
}
