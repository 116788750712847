@import "src/style/variable";
@import "src/style/button";

.formSection {
  background-color: $form-section-background-color;
  padding: $vertical-spacing-xl $horizontal-spacing-sm;
  display: grid;
  row-gap: 40px;
  border-radius: $border-radius-sm;

  :global(.skipButton) {
    font-weight: $font-light;
    text-transform: initial;
    text-decoration-line: underline;
    padding: 5px;
    color: $outlined-primary-button-text-color;
  }
}
