$breakpoint-xs-number: 0;
$breakpoint-sm-number: 600;
$breakpoint-md-number: 900;
$breakpoint-lg-number: 1360;
$breakpoint-xl-number: 1536;

$breakpoint-xs: $breakpoint-xs-number + px;
$breakpoint-sm: $breakpoint-sm-number + px;
$breakpoint-md: $breakpoint-md-number + px;
$breakpoint-lg: $breakpoint-lg-number + px;
$breakpoint-xl: $breakpoint-xl-number + px;

:export {
  breakpointXs: $breakpoint-xs-number;
  breakpointSm: $breakpoint-sm-number;
  breakpointMd: $breakpoint-md-number;
  breakpointLg: $breakpoint-lg-number;
  breakpointXl: $breakpoint-xl-number;
}
