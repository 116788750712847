@import "./colors";
@import "./fonts";
@import "./spacings";
@import "./utilities";

//form
$form-element-spacing: $vertical-spacing-md;

$display-label-on-focus: none;

//text-field
//primary
$text-field-background-color: $white;
$text-field-text-color: $dark-green;
$text-field-border-radius: $border-radius-sm;
$text-field-place-holder-font-size: $font-size-sm;
$text-field-place-holder-opacity: 50%;
$text-field-border-focus: 2.5px solid $theme-primary;

//secondary
$text-field-background-color-secondary: $light-cream;

//transparent
$text-field-border-transparent: 1px solid $light-cream;
$text-field-color-transparent: $separator-hover-color;
$text-field-background-color-transparent: transparent;

$input-error-icon: url('/asset/image/icon/ErrorIcon.svg');
$text-helper-error-color: $text-error-color;
$text-helper-error-font-size: $font-size-xs;
$text-field-border-error: 2.5px solid $input-error-color;
$input-error-icon-width: 15px;
$input-error-icon-height: 15px;
$input-error-icon-right-position: 20px;

//checkbox
$checkbox-button-contained-background-color: $white;
$checkbox-button-contained-active-background-color: rgba($dark-cream, 0.5);
$checkbox-button-contained-delete-background-color: $middle-cream;

$checkbox-light-color: $white;

:export {
  textFieldBackgroundColor: $text-field-background-color;
  textFieldTextColor: $text-field-text-color;
  textFieldBorderRadius: $text-field-border-radius;
  textFieldPlaceHolderFontSize: $text-field-place-holder-font-size;
  textFieldPlaceHolderOpacity: $text-field-place-holder-opacity;

  textFieldBackgroundColorSecondary: $text-field-background-color-secondary;

  textFieldBorderTransparent: $text-field-border-transparent;
  textFieldColorTransparent: $text-field-color-transparent;
  textFieldBackgroundColorTransparent: $text-field-background-color-transparent;

  displayLabelOnFocus: $display-label-on-focus;
  textFieldBorderFocus: $text-field-border-focus;
  textHelperErrorColor: $text-helper-error-color;
  textHelperErrorFontSize: $text-helper-error-font-size;
  textFieldForderError: $text-field-border-error;
  inputErrorIconWidth: $input-error-icon-width;
  inputErrorIconHeight: $input-error-icon-height;
  inputErrorIconRightPosition: $input-error-icon-right-position;
}
