@import "src/style/variable";
@import "src/style/mixins";

.titleEdit {
  input, textarea {
    font-size: $font-size-lg;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1.4px;
  }
}

.descriptionEdit {
  textarea {
    font-size: $font-size-md;
    font-weight: 500;
    letter-spacing: 1.12px;
  }
}
