@import "src/style/variable";
@import "src/style/mixins";

.coacheeNeedContainer {
  position: relative;
  width: 165px;
  height: 165px;

  .coacheeNeed {
    position: absolute;
    width: 165px;
    height: 165px;
    cursor: pointer;

    .circle {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: $primary-circle-color;
      text-align: center;
      transition: all 0.3s ease-in-out;
      @include center-child();

      .text {
        @include vertical-center();
        max-width: 130px;
      }

      &.filled {
        background-color: $secondary-circle-color;
      }

      &.done {
        background-color: $very-light-green;
      }
    }

    &:hover {
      animation: hover 0.3s ease-in-out forwards;

      .circle {
        background-color: $secondary-circle-color;
      }
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .coacheeNeedContainer {
    width: 130px;
    height: 130px;

    .coacheeNeed {
      width: 130px;
      height: 130px;
    }
  }
}

@keyframes hover {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.20);
  }
  100% {
    transform: scale(1.15);
  }
}
