@import "src/style/variable";
@import "src/style/mixins";

.container {
  margin: 0 0 20px;

  .HistoryButton {
    display: flex;
    margin : 20px auto 10px;
  }

  .stateCreation {
    min-height: 140px;
  }
}

