@import "src/style/variable";
@import "src/style/mixins";

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: $vertical-spacing-xs;
  margin-bottom: 30px;
  padding: 30px 25px;
  border-radius: $border-radius-md;
  width: fit-content;


  h3 {
    color: $white;
  }
}

:global(.linkedLight) {
  background: linear-gradient(0deg, rgba(98, 130, 105, 0.30) 0%, rgba(98, 130, 105, 0.30) 100%), $theme-secondary-very-light;
}

:global(.linkedDark) {
  background: $theme-secondary-middle;
}

@media (max-width: $breakpoint-sm) {
  .container {
    width: calc(100% - 50px);
  }
}
