@import "src/style/mixins";
@import "src/style/variable";

#registerPage {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: $theme-secondary-dark;
  @include center-child();

  #boxContainer {
    height: 100%;
    flex-direction: column;
    @include center-child();
  }

  .formTitle {
    text-align: center;
    max-width: 400px;
    letter-spacing: 0.1em;
    margin: 20px 0 20px;
    position: relative;
  }

  #logo {
    z-index: 1;
  }

  .wave {
    display: none;
    position: absolute;
    top: 0;
    height: 100%;
    width: 376px;
  }

  #firstWave {
    z-index: 10;
    background-image: $wave-background-dark-cream;
    left: -100px;
  }

  #secondWave {
    z-index: 9;
    background-image: $wave-background-middle-cream;
    left: 140px;
  }

  #thirdWave {
    z-index: 8;
    background-image: $wave-background-very-light-green;
    left: 350px;
  }

  .loginLinkText {
    .loginLink {
      cursor: pointer;
    }
  }

  @media (min-width: $breakpoint-lg) {
    #boxContainer {
      margin-left: 700px;
    }

    .wave {
      display: block;
    }
  }
}

@media (max-width: $breakpoint-lg) {
  #registerPage {
    position: relative;
    padding: $vertical-spacing-lg 0 $vertical-spacing-xl;
  }
}

.changeLanguage{
  padding-top: 20px;
}

.changeLanguageButton{
  color:$font-color-light-regular !important;
  border-color: $font-color-light-regular !important;
  padding: 5px 15px !important;
}