@import "src/style/variable";
@import "src/style/mixins";

.updateCoacheeFormComponent {
  display: grid;
  gap: 20px;

  * > p {
    margin-bottom: 10px;
  }

  :global(.MuiFormControl-root) {
    width: 100%;
  }

  .descriptionLabel {
    display: flex;
    justify-content: space-between;

    .lengthCount {
      font-size: $font-size-xs;
      font-weight: $font-regular;
      color: $font-color-dark;
    }
  }
}
