#settingPage {
  .sectionProfile {
    text-align: left;

    .title {
      margin-bottom: 70px;
    }
  }

  .sectionSubscription {
    text-align: left;

    .title {
      margin-bottom: 35px;
    }
  }
}
