@import "src/style/variable";
@import "src/style/mixins";

.addTag {
  margin-left: 10px;

  &:global(.inputSelect) {
    position: relative;

    &.opened {
      :global(.action-button) {
        display: none;
      }
    }
  }

  .form {
    @include vertical-center();
    position: relative;
    top: 15px;

    > * {
      margin: 0;
    }
  }
}
