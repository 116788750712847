@import "src/style/variable";
@import "src/style/mixins";

.moduleCardContainer {
  display: flex;
  margin-bottom: $vertical-spacing-lg;

  .titleContainer {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }

  .title, .subTitle {
    color: $font-color-dark
  }

  .backIcon {
    margin-top: -5px;
    margin-left: -10px;

    svg {
      font-size: 30px;
    }
  }
}
