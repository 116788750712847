@import "src/style/variable";
@import "src/style/mixins";

.thumbnailContainer {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 125px;
  height: 100px;
  padding: 35px 20px;
  background-color: $thumbnail-background-color;
  border-radius: $border-radius-md;
  text-transform: uppercase;

  &:hover {
    background-color: $thumbnail-background-color-hover;
  }

  .circleContainer {
    width: 54px;
  }

  .text {
    @include ellipsisMultiLine(2);

    text-align: center;
    margin-top: $vertical-spacing-md;
    font-weight: $font-semi-bold;
    padding-bottom: 35px;
  }
}
