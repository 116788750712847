@import "src/style/variable";
@import "src/style/mixins";

.checkboxButtonContainer {
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;

  &:hover, &:focus {
    :global(.contained) {
      background-color: $checkbox-button-contained-active-background-color;
    }

    :global(.outlined) {
      background-color: $checkbox-button-contained-active-background-color;
    }

    .deleteButton {
      @include vertical-center();

      display: block;
      right: 0;
      height: 40px;

      :global(button) {
        height: 40px;

        &:hover {
          background-color: $checkbox-button-contained-delete-background-color;
        }

        :global(svg) {
          height: 20px;
        }
      }
    }
  }

  .deleteButton {
    display: none;
  }

  .formControl {
    padding: $vertical-spacing-xs 20px;
    border-radius: $border-radius-lg;
    position: relative;
    height: fit-content;
    width: 100%;
    margin: 0;

    :global(.MuiCheckbox-root) {
      padding: 0;
    }

    .labelContainer {
      display: flex;
      flex-direction: column;
      word-break: break-word;

      .label {
        margin-left: $horizontal-spacing-xs;

        &.checked {
          text-decoration-line: line-through;
        }
      }

      .date {
        font-size: $font-size-xxs;
        margin-left: $horizontal-spacing-xs;

        &.checked {
          text-decoration-line: line-through;
        }
      }
    }
  }

  :global(.contained) {
    background-color: $checkbox-button-contained-background-color;
  }
}
