@import "src/style/variable";
@import "src/style/mixins";

#headerComponent {
  position: relative;
  text-align: center;

  .container {
    padding: 70px 0;

    h1 {
      text-transform: uppercase;
    }

    h1, h4 {
      position: relative;
    }

    .tabContainer {
      padding: $vertical-spacing-lg 0 $vertical-spacing-xxl 0;
      display: flex;
      align-items: center;
      flex-direction: column;

      :global(.MuiTabs-flexContainer) {
        gap: 100px;
      }

      :global(.MuiTab-root) {
        padding: 0;
      }

      :global(.MuiTabs-indicator) {
        display: none;
      }
    }
  }
}
