@import "src/style/variable";
@import "src/style/mixins";

.addSomethingContainer {
  @include center-child();
  gap: 30px;

  @media (max-width: $breakpoint-sm) {
    flex-direction: column;
  }
}
