@import "src/style/variable";
@import "src/style/mixins";

.titleContainer {
  display: flex;
  align-items: center;
  width: 95%;
}

.iconContainer {
  @include center-child();

  .editButton {
    margin: 0 20px;
  }
}

.formContainer {
  display: flex;
  align-items: center;

  > * {
    margin: 0;
  }

  :global(.MuiInputBase-root) {
    height: 36px;
    min-width: 160px;
    margin-right: 7px;
  }
}

