@import "src/style/variable";
@import "src/style/mixins";

.separatorContainer {
  display: flex;
  margin: $vertical-spacing-lg 0;
  align-items: center;
  overflow: hidden;
  height: 40px;
  cursor: pointer;

  .titleContainer {
    position: relative;
    width: fit-content;
  }

  .iconContainer {
    margin: 0 20px;
  }

  .editIcon {
    display: none;
  }

  &:hover {
    .editIcon {
      margin: 0;
      display: flex
    }

    .textContent {
      color: $separator-hover-color;
    }
  }

  .textContent {
    color: $separator-color;
    text-transform: none;
    text-align: left;
  }

  .updateIcon {
    position: relative;
    margin-left: -45px;
  }
}
