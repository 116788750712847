@import "src/style/variable";

.listingContainer {
  display: grid;
  gap: $vertical-spacing-xs;
}

.hideListedItemsButton {
  margin-bottom: $vertical-spacing-sm;
  display: flex;
  justify-content: flex-end;
}

.formContainer {
  margin-top: $vertical-spacing-lg;
}
