@import "src/style/variable";
@import "src/style/mixins";

.box {
  position: absolute;
  bottom: 7px;
  right: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  margin: 0;
  padding: 5px $horizontal-spacing-xs 5px;
  background-color: $theme-secondary-very-light;
  color: $white;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;

  &.hide {
    background-color: transparent;
  }

  &.saving {
    background-color: $theme-primary-very-light;

    .text {
      color: $theme-secondary;
    }
  }

  &.error {
    background-color: $theme-danger;
  }

  .text {
    font-size: 14px;
  }
}
