@import "src/style/variable";
@import "src/style/mixins";

.card {
  display: flex;
  flex-direction: column;
  padding: $vertical-spacing-xs 20px;
  border-radius: $border-radius-sm;
  transition: all 0.2s ease-in-out;

  &:global(.white) {
    background-color: $white;
  }

  &:global(.light) {
    background-color: $theme-primary-very-light;
  }

  &:global(.dark) {
    background-color: $theme-primary;
  }

  .cardContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .actionIcon {
    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .avatar {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .name {
    text-transform: uppercase;
    text-align: left;
  }

  .childrenContainer {
    transition: height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    overflow: hidden;

    &.childActive, &.childInactive {
      height: 0;
    }

    .children {
      display: flex;
      flex-direction: column;
      gap: $horizontal-spacing-xs;
      width: 100%;
      padding-top: $vertical-spacing-sm;
    }
  }

  .active, .inactive {
    transition: all 0.1s ease-in-out;
    transform: translateY(2px);
  }

  .active {
    transform: rotate(180deg) translateY(2px);
    transform-origin: center;
  }
}

@for $i from 1 through 15 {
  :global(.child-#{$i}) {
    height: $i * 60px
  }
}
