@import "src/style/variable";
@import "src/style/mixins";

:global(.flash) {
  #footerContainer {
    #footerLogoContainer, #footerText {
      color: $footer-text-color-flash-theme;
    }

    .link {
      color: $footer-text-color-flash-theme;
      text-decoration-color: $footer-text-color-flash-theme;

      &:hover {
        color: $footer-text-hover-color-flash-theme;
        text-decoration-color: $footer-text-hover-color-flash-theme;
      }
    }
  }

  .darkTheme {
    display: none;
  }
}

:global(.textured), :global(.dark), :global(.light) {
  .flashTheme {
    display: none;
  }
}

#footerContainer {
  padding: $vertical-spacing-xl $vertical-spacing-xl;
  position: relative;
  min-height: 255px;

  .linkContainer {
    @include center-child();
    margin-bottom: $vertical-spacing-xl;

    .rightLinksContainer {
      display: flex;
      justify-content: flex-end;
      align-self: flex-end;
    }

    .leftLinksContainer {
      display: flex;
      justify-content: flex-start;
      align-self: flex-end;
    }
  }

  #footerLogoContainer {
    color: $footer-logo-color;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #footerText {
    text-align: center;
    font-weight: $font-semi-bold;
    color: $footer-text-color;
  }

  .link {
    color: $footer-text-color;
    text-decoration-color: $footer-text-color;
    font-size: $font-size-sm;
    font-weight: $font-semi-bold;
    text-transform: uppercase;

    &:hover {
      color: $footer-text-hover-color;
      text-decoration-color: $footer-text-hover-color;
    }
  }

  #contactUsContainer {
    display: flex;
    justify-content: right;
    margin-bottom: $vertical-spacing-md;

    .contactUs {
      span {
        height: 22px;
      }
    }
  }
}

@media (max-width: $breakpoint-md) {
  #footerContainer {
    padding: 60px 0;

    .linkContainer {
      position: relative;
      gap: $vertical-spacing-xl;

      .leftLinksContainer, .rightLinksContainer {
        justify-content: center;
        align-self: center;
      }
    }
  }
}
