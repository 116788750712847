@import "src/style/variable";

.container {
  padding-bottom: $vertical-spacing-xl;
  display: flex;
  justify-content: space-between;

  @media (max-width: $breakpoint-sm) {
    padding-bottom: $vertical-spacing-lg;
    flex-direction: column;
    align-items: center;

    button {
      margin-top: $vertical-spacing-sm;
      max-width: fit-content;
    }
  }
}
