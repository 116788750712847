@import "variable";
@import "text";
@import "form";
@import "alert";

html,
body {
  height: 100%;
  margin: 0;
  padding: 0 !important;
  overflow: auto !important;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

#root {
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
