@import "src/style/variable";
@import "src/style/mixins";

.moduleCardContainer {
  margin-bottom: $vertical-spacing-lg;

  .titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $vertical-spacing-sm;
  }

  .title, .subTitle {
    color: $font-color-dark;

    .left {
      text-align: left;
    }

    .center {
      text-align: center;
    }

    .right {
      text-align: right;
    }
  }
}

