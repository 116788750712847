@import "src/style/variable";
@import "src/style/mixins";

.tag {
  .tagButton {
    text-transform: initial;
    position: relative;
    height: 35px;
  }

  &.isInactive{
    pointer-events: none;
  }

  &.showCloseIconOnHover {
    .isSelected {
      background-color: $theme-primary-middle;
      transition: padding 0.2s ease-in-out;

      &:hover {
        padding-right: 22px;
        padding-left: 8px;
      }

      :global(.MuiButton-endIcon) {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
      }

      &:hover :global(.MuiButton-endIcon) {
        opacity: 1;
      }
    }
  }

  .isNotSelected {
    :global(.MuiButton-endIcon) {
      display: none;
    }
  }
}
