@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "./colors";

$font-color-light: $light-cream;
$font-color-light-regular: $middle-cream;
$font-color-regular: $green;
$font-color-dark: $dark-green;
$font-color-heavy-dark: $very-dark-green;

$font-size-xxs: 0.625rem; // 10px
$font-size-xs: 0.688rem; // 11px
$font-size-sm: 0.875rem; // 14px
$font-size-sm-md: 0.938rem; // 15px
$font-size-md: 1rem; // 16px
$font-size-lg: 1.25rem; // 20px
$font-size-xl: 2rem; // 32px
$font-size-xxl: 3rem; // 48px

$font-ultra-light: 300;
$font-light: 400;
$font-regular: 500;
$font-semi-bold: 600;
$font-bold: 700;

$font-family: 'Urbanist', sans-serif;

$letter-spacing: 0.07em;

$h1FontSize: $font-size-xxl;
$h1FontWeight: $font-light;
$h2FontSize: $font-size-xl;
$h2FontWeight: $font-regular;
$h3FontSize: $font-size-lg;
$h3FontWeight: $font-bold;
$h4FontSize: $font-size-lg;
$h4FontWeight: $font-regular;
$h5FontSize: $font-size-lg;
$h5FontWeight: $font-bold;
$smFontSize: $font-size-md;
$smFontWeight: $font-regular;
$xsFontSize: $font-size-sm;
$xsFontWeight: $font-regular;
$xxsFontSize: $font-size-xxs;
$xxsFontWeight: $font-regular;

:export {
  fontFamily: $font-family;
  letterSpacing: $letter-spacing;
  fontRegular: $font-regular;
  fontSizeBase: $font-size-md;
  h1FontSize: $h1FontSize;
  h1FontWeight: $h1FontWeight;
  h2FontSize: $h2FontSize;
  h2FontWeight: $h2FontWeight;
  h3FontSize: $h3FontSize;
  h3FontWeight: $h3FontWeight;
  h4FontSize: $h4FontSize;
  h4FontWeight: $h4FontWeight;
  h5FontSize: $h5FontSize;
  h5FontWeight: $h5FontWeight;
  smFontSize: $smFontSize;
  smFontWeight: $smFontWeight;
  xsFontSize: $xsFontSize;
  xsFontWeight: $xsFontWeight;
  xxsFontSize: $xxsFontSize;
  xxsFontWeight: $xxsFontWeight;
}
