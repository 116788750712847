@import "variable/fonts";

body {
  h1, h2, h3, h4, h5 {
    text-transform: uppercase;

    &.initial-text-transform {
      text-transform: initial;
    }
  }

  h1, h2, h3, h4, h5, p, span, a {
    font-family: $font-family;

    &.font-color-light {
      color: $font-color-light
    }

    &.font-color-light-regular {
      color: $font-color-light-regular;
    }

    &.font-color-regular {
      color: $font-color-regular;
    }

    &.font-color-dark {
      color: $font-color-dark;
    }

    &.font-color-heavy-dark {
      color: $font-color-heavy-dark;
    }
  }
}
