@import "src/style/variable";
@import "src/style/mixins";

.iconButton {
  padding: 0;
  width: 100%;
  height: 100%;

  .icon {
    @include center-child();
    position: absolute;

    :global(svg) {
      width: 100%;
      height: 100%;
    }
  }
}
