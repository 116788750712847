@import "src/style/mixins";
@import "src/style/variable";

#loginFormContainer {
  #loginForm {
    max-width: 320px;
    text-align: center;
  }

  .loginButton {
    margin-top: 45px;
  }

  .resetPassword {
    position: relative;
  }
}

@media (min-width: $breakpoint-sm) {
  #loginFormContainer {
    #loginForm {
      min-width: 320px;
      max-width: none;
    }
  }
}
