@import "src/style/variable";

.dropdownThumbnail {
  border-radius: 30px !important;
  padding: 0 !important;

  :global(.MuiAccordionSummary-root),
  :global(.MuiAccordionSummary-root.Mui-expanded),
  :global(.MuiAccordionSummary-root.Mui-focusVisible) {
    background-color: initial;
  }
}
