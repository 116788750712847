@import "src/style/variable";
@import "src/style/mixins";

.ghostContainer {
  margin: 0;

  .labels {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    font-size: $font-size-xs;
    color: $theme-secondary-dark;
    letter-spacing: 0.77px;
    margin: $vertical-spacing-xs 0;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &:focus-within {
    .labels {
      opacity: 1;
    }
  }

  .ghostInput {
    * {
      padding: 0 !important;
    }

    input, textarea {
      width: 100%;
      border-radius: $border-radius-md;
      transition: all 0.3s ease-in-out;
      padding: $vertical-spacing-sm 3px !important;
      border: 1px solid transparent;

      &:hover {
        border-color: $theme-primary-middle;
      }

      &:focus {
        background-color: $theme-primary-very-light;
        padding: $vertical-spacing-sm !important;
      }
    }
  }
}
