@import "src/style/variable";

.container {
  margin-bottom: $vertical-spacing-xxl;
  text-align: center;
  border-radius: $border-radius-md;

  &:global(.primary) {
    background-color: $card-body-primary-background-color;
    color: $font-color-light;
  }

  &:global(.secondary) {
    background-color: $card-body-secondary-background-color;
    color: $font-color-dark;
  }

  &:global(.small) {
    max-width: 900px;

    .layoutContainer {
      padding: $vertical-spacing-xl 90px;

      @media (max-width: $breakpoint-sm) {
        padding: $vertical-spacing-md 0;
      }
    }
  }

  &:global(.smallPadding) {
    .layoutContainer {
      padding: 70px $vertical-spacing-lg;

      @media (max-width: $breakpoint-sm) {
        padding: $vertical-spacing-md 0;
      }
    }
  }

  .layoutContainer {
    padding: $vertical-spacing-xl 120px;
  }

  @media (max-width: $breakpoint-lg) {
    .layoutContainer {
      padding: $vertical-spacing-xl 20px;
    }
  }

  @media (max-width: $breakpoint-md) {
    .layoutContainer {
      padding: $vertical-spacing-xl 0;
    }
  }
}
