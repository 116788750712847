@import "src/style/variable";
@import "src/style/mixins";

h5.title {
  margin: 20px 0;
  color: $body-dark-color;
  font-weight: $font-semi-bold;

  &:global(.noMargin) {
    margin: 0;
  }
}
