@import "src/style/variable";
@import "src/style/mixins";

.cardContent {
  display: flex;
  flex-direction: column;
  gap: $vertical-spacing-sm;
  color: $theme-secondary-dark;

  .description {
    @include ellipsisMultiLine(3);
  }

  .title {
    @include ellipsisMultiLine(2);
  }
}

.container {
  display: flex;
  gap: $horizontal-spacing-xs;
  text-align: left;
  align-items: center;
  justify-content: space-between;

  .deadline {
    display: flex;
    gap: $horizontal-spacing-xs;
  }
}
