@import "src/style/variable";
@import "src/style/variable/animation";

.MuiPopover-paper {
  border-radius: $border-radius-sm;

  .MuiMenu-list {
    padding: 0;

    li {
      transition: all .4s;
      position: relative;
      overflow: hidden;
      z-index: 1;
      border: none;

      // Background on hover
      &:after {
        content: '';
        position: absolute;
        right: 10px;
        left: 10px;
        width: 0;
        top: 5px;
        bottom: 5px;
        border-radius: $border-radius-xl;
        z-index: -1;
      }

      &:hover {
        &:after {
          animation: reveal-right-left 0.6s forwards;
          background-color: rgba($navbar-popover-hover, 0.4);
          width: 88%;
        }
      }

      &:focus, &:active {
        background-color: transparent;
        color: $flash-cream;
      }

      &:hover, &:focus {
        background: none;
      }

      // separator
      &::before {
        display: block;
        content: "";
        position: absolute;
        bottom: 0;
        left: 10px;
        right: 10px;
        height: 1px;
        background-color: $popover-separator-color;
      }

      &:last-child {
        &::before {
          display: none;
        }
      }
    }
  }
}

.MuiTouchRipple-root {
  display: none;
}
