@import "src/style/variable";
@import "src/style/mixins";

.statusCardContainer {
  display: flex;
  padding-top: 15px;
  gap: 5px;

  .contentOverlay {
    background-color: $card-button-overlay-hover;
    border-radius: $border-radius-md;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    &:hover {
      opacity: 100%;
    }
  }

}
