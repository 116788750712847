@import "src/style/mixins";
@import "src/style/variable";

#registerFormContainer {
  @include center-child();

  #registerForm {
    width: 320px;
    text-align: center;
  }

  .registerButton {
    margin-top: 45px;
  }

  .confirmationCheckbox, .checkbox {
    color: $checkbox-light-color;
  }
}
