@import "src/style/variable";
@import "src/style/mixins";

.form {
  position: relative;

  > * {
    margin: $vertical-spacing-xs 0;
  }

  .labels {
    display: flex;

    .lengthCount {
      margin-left: auto;
      font-size: $font-size-xs;
      font-weight: $font-regular;
      color: $font-color-dark;
    }
  }

  .input {
    margin-top: 0;

    textarea {
      letter-spacing: $letter-spacing;
      line-height: 19px;
      font-size: $font-size-sm;
    }
  }
}
