@import "src/style/variable";
@import "src/style/mixins";

.progressCircleContainer {
  @include center-child();

  &:global(.small) {
    height: 25px;

    .progressCircleIcon {
      width: 25px;
      height: 25px;
    }
  }

  &:global(.medium) {
    height: 40px;

    .progressCircleIcon {
      width: 40px;
      height: 40px;
    }
  }

  &:global(.large) {
    height: 60px;

    .progressCircleIcon {
      width: 60px;
      height: 60px;
    }
  }

  .progressCircleIcon {
    color: $progress-circle-background-color;
  }

  .numbers {
    @include center-child();
    margin-left: 7px;
  }
}
