@import "src/style/variable";
@import "src/style/mixins";

.displayerContainer {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: $horizontal-spacing-sm;
  background-color: $theme-secondary;
  padding: $vertical-spacing-xl $vertical-spacing-xl calc(#{$vertical-spacing-xl} - #{$horizontal-spacing-sm});
  border-radius: $border-radius-md;
  min-height: 640px;

  @media (max-width: $breakpoint-sm) {
    padding: $vertical-spacing-xl $vertical-spacing-md;
  }
}
