@import "src/style/variable";
@import "src/style/mixins";

.MuiAutocomplete-root {
  position: relative;
  width: 100%;

  .MuiFormControl-root {
    .MuiFormLabel-root {
      z-index: 3;
      padding: 3px;
    }

    .MuiInputBase-root.MuiInputBase-formControl {
      z-index: 2;
      padding: 8px 20px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

      .MuiAutocomplete-endAdornment {
        right: 20px;
        top: 4px;
        @include vertical-center();

        .MuiAutocomplete-popupIndicator {
          &:hover {
            background-color: transparent;
          }
        }

        .MuiAutocomplete-popupIndicator {
          height: 20px;
          width: 20px;
          color: $input-select-arrow-down-color;
        }
      }
    }
  }
}

.MuiAutocomplete-popper {
  &.MuiPopper-root {
    z-index: 1300;
  }

  &.MuiPopper-root {
    &.icons, &.rounded {
      z-index: 20;
    }
  }

  .MuiPaper-root {
    box-shadow: none;

    &.secondary {
      background-color: $text-field-background-color-secondary;
    }
  }

  &.squared {
    &[data-popper-placement="top"] .MuiPaper-root {
      border-radius: $border-radius-sm $border-radius-sm 0 0;
      margin-bottom: -5px;
      border-bottom: 2.5px solid $theme-primary;
    }

    &[data-popper-placement="bottom"] .MuiPaper-root {
      border-radius: 0 0 $border-radius-sm $border-radius-sm;
      margin-top: -5px;
      border-top: 2.5px solid $theme-primary;
    }
  }

  .MuiAutocomplete-listbox .MuiAutocomplete-option {
    padding: 9px 20px;
    color: $input-select-text-color;

    &.Mui-focused {
      background-color: $input-focused-color;
    }

    &.MuiAutocomplete-option[aria-selected="true"] {
      background-color: $input-selected-color;
    }
  }

  .no-options {
    text-align: center;

    .no-option-text {
      font-size: $font-size-sm;
      color: $input-select-no-option-text-color;
    }
  }

  .add-button {
    text-align: center;
    padding-bottom: 20px;

    button {
      text-align: center;

      font-size: $font-size-xxs;
      padding-top: 8px;

      svg {
        color: $font-color-heavy-dark;
        margin-top: -1px;
        margin-right: 0;
      }
    }
  }
}

.MuiAutocomplete-root {
  .expand-icon {
    cursor: pointer;
    width: 40px;
    height: 40px;
    transition: all 0.1s ease-in-out;
  }

  &.isOpen {
    .expand-icon {
      transform: rotate(180deg);
    }

    &.secondary:after {
      background-color: $text-field-background-color-secondary;
    }
  }

  &.rounded {
    .MuiInputBase-root.MuiInputBase-formControl {
      border-radius: $border-radius-lg;
    }

    .MuiOutlinedInput-notchedOutline {
      top: 0;
      border-radius: $border-radius-lg;
    }
  }
}

.MuiAutocomplete-popper {
  &[data-popper-placement="top"] .MuiPaper-root.rounded {
    border-radius: $border-radius-lg $border-radius-lg 0 0;
    margin-bottom: 1px;
    border-bottom: none;
  }

  &[data-popper-placement="bottom"] .MuiPaper-root.rounded {
    border-radius: 0 0 $border-radius-lg $border-radius-lg;
    margin-top: 1px;
    border-top: none;
  }
}

.MuiAutocomplete-popper {
  .icons {
    z-index: 20;

    .icon-option {
      width: 40px;
      height: 40px;
      margin-right: 15px;
    }

    .MuiAutocomplete-listbox .MuiAutocomplete-option {
      padding: 5px 10px;
    }
  }

  .MuiPaper-root.icons {
    background-color: $input-select-icons-dropdown-background-color;
  }

  &[data-popper-placement="top"] .MuiPaper-root.icons {
    border-radius: $border-radius-xl $border-radius-xl 0 0;
    padding-bottom: 30px;
    margin-bottom: -29px;
    border-bottom: none;
  }

  &[data-popper-placement="bottom"] .MuiPaper-root.icons {
    border-radius: 0 0 $border-radius-xl $border-radius-xl;
    padding-top: 30px;
    margin-top: -29px;
    border-top: none;
  }

  &.rounded:after, &.icons:after {
    content: '';
    position: absolute;
    z-index: -1;
    height: 50px;
    width: 100%;
    background-color: $text-field-background-color;
  }

  &[data-popper-placement="top"] {
    &.rounded:after, &.icons:after {
      bottom: -40px;
    }
  }

  &[data-popper-placement="bottom"] {
    &.rounded:after, &.icons:after {
      top: -40px;
    }
  }
}

.MuiAutocomplete-root {
  &.icons, &.rounded {
    .MuiFormControl-root {
      z-index: 21;
    }
  }

  &.icons {
    .MuiFormControl-root {

      .MuiInputBase-root.MuiInputBase-formControl {
        border-radius: $border-radius-xxl;
        background-color: $input-select-icons-background-color;
      }
    }

    .icon-option {
      width: 58px;
      height: 58px;
      margin-right: 5px;
    }
  }
}
