@import "variable/colors";

@mixin ellipsis($max-width) {
  display: inline-block;
  max-width: $max-width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin transparentBackgroundColorMixin($alpha,$color) {
  background-color: rgba(red($color), green($color), blue($color), $alpha);
}

@mixin ellipsisMultiLine($line-clamp) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: $line-clamp;
  -webkit-box-orient: vertical;
}

@mixin horizontal-center {
  margin: 0;
  position: absolute;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

@mixin vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin center-child {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin hiddenElement {
  position: absolute;
  visibility: hidden;
  opacity: 0;
}

@mixin visibleElement {
  position: relative;
  visibility: visible;
  opacity: 1;
}

@mixin backgroundCover {
  content: '';
  position: absolute;
  background-size: cover;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

@mixin generatedBackground {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  filter: contrast(110%) brightness(110%);
  background: radial-gradient(circle at 0% 0%, rgba(16, 42, 38, 0.95), rgba(16, 42, 38, 0.95)),
  url("data:image/svg+xml,%3Csvg viewBox='0 0 255 255' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='1.50' numOctaves='1' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
  width: 100%;
  height: 100%;
}

@mixin lineSeparator($color) {
  vertical-align: bottom;
  width: 100%;
  margin-right: -100%;
  border-top: 1px solid $color;
}

@mixin pyramidStage($stageWidth, $pyramidAngle) {
  width: $stageWidth;
  -webkit-clip-path: polygon(50% $pyramidAngle, 100% 100%, 0 100%);
  clip-path: polygon(50% $pyramidAngle, 100% 100%, 0 100%);
}
