@import "src/style/variable";
@import "src/style/mixins";

.input {
  &:global(.MuiTextField-root) {
    border: 1px solid $theme-primary;
    box-shadow: none !important;
    border-radius: $border-radius-sm;

    input, label {
      margin-left: 33px;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 1.12px;
    }

    label {
      &:focus, &:focus-within {
        display: none;
      }
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 18px;
      width: 18px;
      height: 100%;
      background-image: url("/asset/image/icon/Loupe.png");
      background-repeat: no-repeat;
      background-position: 5px center;
      background-size: 10px;
      z-index: 5;
      transform: scale(2.3);
      cursor: pointer;
    }
  }
}

.searcher{
  :global(.MuiAutocomplete-endAdornment) {
    display: none;
  }
}

