@import "variable/form";

form > * {
  margin: $form-element-spacing 0;

  .MuiFormControl-root {
    .MuiOutlinedInput-root.Mui-error::before {
      background: $input-error-icon no-repeat,
    }
  }
}
