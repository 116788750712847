@import "src/style/variable";
@import "src/style/mixins";

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}

.text {
  margin: 0;

  .title {
    margin-bottom: 10px;
  }
}
