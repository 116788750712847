@import "src/style/variable";

.container {
  position: relative;

  &>* {
    margin-top: 25px;
    margin-bottom: 10px;
  }

  .borderInputFocused {
    border: 1px solid $dark-cream;
    border-radius: 5px;
  }

  .borderInputError {
    border: 1px solid $danger-zone-color;
    border-radius: 5px;
  }

  .iconError{
    position: absolute;
    right: 20px;
    top: 9px;
    background: none !important;
    width: 16px;
  }

  .iconErrorHidden{
    display: none;
  }

  .iconArrow,
  .iconLoading {
    position: absolute !important;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    background: none !important;
    padding: 9px;
  }

  .iconArrow {
    &:hover {
      background: rgba(255, 191, 115, 0.40) !important;
    }
  }

  .iconLoading {
    background: rgba(255, 191, 115, 0.40) !important;
    cursor: progress !important;
  }

  .inputComment,
  .inputCommentFocused {
    width: 100% !important;
  }

  .inputCommentFocused {
    width: 90% !important;
  }
}

.errorMessage{
  color: $danger-zone-color;
  font-size: 12px;
  margin-top: 5px;
  padding-bottom: 10px;
}
