@import "src/style/variable";
@import "src/style/mixins";

.moduleSectionLayout {
  position: relative;
  padding: 20px 19px;
  border-radius: $border-radius-md;
  margin: 30px 0;

  &:global(.cream) {
    background-color: $module-card-creme-color;
  }

  .title {
    margin-bottom: 10px;
  }

  .loading {
    position: absolute;
    right: 0;
    top: 0;
    padding: 25px;
  }
}
