@import "src/style/variable";
@import "src/style/mixins";

.container {
  > * {
    margin-bottom: $vertical-spacing-md;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}

.editIcon {
  cursor: pointer;
}

.editButton {
  left: 3px;
}

.listingContainer {
  display: flex;
  flex-direction: column;
  gap: $horizontal-spacing-sm;
}
