@import "src/style/variable";
@import "src/style/mixins";

.slick-arrow {
  position: absolute;
  color: $slick-navigation-arrow-color;
  width: 100px;
  padding: 0;
  cursor: pointer;

  &:hover {
    color: $slick-navigation-arrow-color-hover;
  }

  > svg {
    height: 30px;
  }

  &.slick-next {
    right: 0;
  }

  &.slick-prev {
    left: 0;
    transform: rotate(180deg);
  }

  &.slick-prev, &.slick-next {
    &:before {
      display: none;
    }
  }
}
