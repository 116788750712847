@import "src/style/variable";

#personalInformationsComponent {
  padding: 40px 0;

  .inputContainer {
    position: relative;

    :global(.save-state) {
      bottom: 4px;
    }
  }

  .title {
    margin-bottom: $vertical-spacing-md;
  }

  .textField {
    margin-top: $vertical-spacing-xs;
  }
}
