@import "src/style/variable";
@import "src/style/mixins";

.tags {
  @include center-child();
  display: flex;
  flex-flow: wrap;
  gap: $vertical-spacing-sm;
  justify-content: flex-start;
  margin-left: -10px;
}
