@import "src/style/variable";
@import "src/style/mixins";

.statusCircleContainer {
  display: flex;

  &:global(.small) {
    .statusCircleIcon {
      width: 20px;
      height: 20px;
    }
  }

  &:global(.medium) {
    .statusCircleIcon {
      width: 40px;
      height: 40px;
    }
  }
}
