@import "src/style/variable";
@import "src/style/mixins";

#coacheeProfileBodyComponentContainer {
  background-color: $body-very-light-color;
  min-height: 500px;
  border-radius: $border-radius-md;
  padding: $vertical-spacing-lg 0;

  .moduleContainer {
    @include center-child();
    padding: 30px;
  }

  .noteContainer, .linkedMandateList {
    padding: 0 42px;
  }
}

@media (max-width: $breakpoint-sm) {
  #coacheeProfileBodyComponentContainer {
    padding: 0;

    .moduleContainer {
      padding: 0;
    }

    .noteContainer, .linkedMandateList {
      padding: 20px 12px;
    }
  }
}
