@import "src/style/variable";
@import "src/style/mixins";

.container {
  display: flex;
  flex-direction: column;
  gap: 74px;
  text-align: center;
  margin-bottom: 170px;
  margin-top: 30px;
}
