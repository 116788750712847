@import "src/style/variable";
@import "src/style/mixins";

.accordion, .accordionLight {
  &:global(.MuiAccordion-root) {
    background-color: $accordion-section-background-color;
    margin-top: 30px;

    &:global(.MuiAccordion-rounded) {
      box-shadow: none;
      border-radius: $border-radius-sm;
    }
  }

  :global(.MuiAccordionSummary-expandIconWrapper) {
    color: $expand-icon-color;
    width: 20px;
    height: 20px;
    min-width: 20px;
  }

  :global(.MuiAccordionSummary-root) {
    height: 51px;
    display: flex;
    align-items: center;
    padding: 0 25px;
    border-radius: $border-radius-sm;

    @media (max-width: $breakpoint-sm) {
      padding: 10px;
    }

    &.summaryLarge {
      padding: 30px 25px;
    }

    @media (min-width: $breakpoint-sm) {
      &:global(.Mui-expanded) {
        border-bottom: 1px solid $accordion-summary-border-color;

        .button {
          :global(.MuiButton-endIcon) {
            margin-left: 14px;
          }

          .buttonText {
            display: block;
          }
        }
      }
    }

    :global(.MuiAccordionSummary-content) {
      display: flex;
      align-items: center;
    }
  }

  :global(.MuiAccordionDetails-root) {
    padding: $horizontal-spacing-md;
    margin: 0;

    :global(.MuiBox-root) {
      margin: 0;
    }
  }

  .icon {
    width: 14px;
  }

  .title {
    color: $font-color-light;
    font-weight: $font-semi-bold;
    text-align: left;

    @media (max-width: $breakpoint-md) {
      margin-right: 50px;
    }

    @media (max-width: $breakpoint-sm) {
      margin-right: 10px;
    }
  }

  .button {
    margin-left: auto;
    margin-right: 125px;
    min-width: fit-content;

    :global(.MuiButton-endIcon) {
      margin-left: 0;
    }

    .buttonText {
      display: none;
    }

    @media (max-width: $breakpoint-md) {
      margin-right: 50px;
    }

    @media (max-width: $breakpoint-sm) {
      margin-right: 15px;
    }
  }
}

.accordionLight {
  &:global(.MuiAccordion-root) {
    background-color: $accordion-section-background-color-light;

    :global(.MuiAccordionSummary-expandIconWrapper) {
      color: $expand-icon-color-dark;
    }
  }

  :global(.MuiAccordionSummary-root) {
    &:global(.Mui-expanded) {
      border-bottom: 1px solid $accordion-summary-border-color-light;
    }
  }

  .title {
    color: $font-color-heavy-dark;
  }
}

@media (max-width: $breakpoint-sm) {
  .accordion, .accordionLight {
    :global(.MuiAccordionDetails-root) {
      padding: $horizontal-spacing-sm $horizontal-spacing-xs;
    }
  }
}
