@import "src/style/variable";
@import "src/style/mixins";

$left-horizontal-level-2-position: 12%;
$left-horizontal-level-1-position: 29%;
$middle-horizontal-level-0-position: 50%;
$right-horizontal-level-1-position: 71%;
$right-horizontal-level-2-position: 88%;
$horizontal-level-outside-left: -12%;
$horizontal-level-outside-right: 112%;
$transition: left 1s 0.1s, opacity 0.2s;
$faster-transition: left 0.7s, opacity 0.2s;
$faster-transition-delayed: left 0.7s 0.2s, opacity 0.2s;

#carousel {
  position: relative;
  width: 100%;
  height: inherit;

  .slick-arrow {
    top: 75px;
  }

  .slide-container {
    position: relative;
    height: inherit;
    overflow: hidden;
    margin: 0 100px;

    .slide {
      @include horizontal-center;
      position: absolute;
      transition: $transition;

      &.left-horizontal-level-2 {
        z-index: 0;
        left: $left-horizontal-level-2-position;
      }

      &.left-horizontal-level-1 {
        z-index: 1;
        left: $left-horizontal-level-1-position;
      }

      &.middle-horizontal-level-0 {
        z-index: 2;
        left: $middle-horizontal-level-0-position;
      }

      &.right-horizontal-level-1 {
        z-index: 1;
        left: $right-horizontal-level-1-position;
      }

      &.right-horizontal-level-2 {
        z-index: 0;
        left: $right-horizontal-level-2-position;
      }

      &.left-exit,
      &.left-enter,
      &.right-exit,
      &.right-enter {
        pointer-events: none;
      }

      &.middle-enter,
      &.left-enter,
      &.right-enter {
        &.middle-horizontal-level-0,
        &.left-horizontal-level-1,
        &.left-horizontal-level-2,
        &.right-horizontal-level-1,
        &.right-horizontal-level-2 {
          transition: none;
        }
      }

      &.left-exit.left-exit-active, &.right-exit.right-exit-active {
        opacity: 0;
      }

      &.right-enter-active, &.left-enter-active {
        opacity: 1;
      }

      &.left-enter {
        &.left-horizontal-level-2 {
          left: $horizontal-level-outside-left;
        }

        &.left-horizontal-level-1 {
          left: $left-horizontal-level-2-position;
        }

        &.left-enter-active {
          &.left-horizontal-level-2 {
            left: $left-horizontal-level-2-position;
          }

          &.left-horizontal-level-1 {
            left: $left-horizontal-level-1-position;
          }
        }
      }

      &.right-enter {
        &.right-horizontal-level-2 {
          left: $horizontal-level-outside-right;
        }

        &.right-horizontal-level-1 {
          left: $right-horizontal-level-2-position;
        }

        &.right-enter-active {
          &.right-horizontal-level-2 {
            left: $right-horizontal-level-2-position;
          }

          &.right-horizontal-level-1 {
            left: $right-horizontal-level-1-position;
          }
        }
      }

      &.left-exit.left-exit-active {
        left: $horizontal-level-outside-left;
      }

      &.right-exit.right-exit-active {
        left: $horizontal-level-outside-right;
      }
    }
  }

  &.faster-transition {
    .slide-container .slide {
      transition: $faster-transition;

      &.right-enter-done.right-horizontal-level-2,
      &.left-enter-done.left-horizontal-level-2 {
        transition: $faster-transition-delayed;
      }

      &.left-enter {
        &.left-horizontal-level-2,
        &.left-horizontal-level-1 {
          left: $horizontal-level-outside-left;
        }
      }

      &.right-enter {
        &.right-horizontal-level-1,
        &.right-horizontal-level-2 {
          left: $horizontal-level-outside-right;
        }
      }
    }
  }
}

.slide-count-1, .slide-count-2, .slide-count-3, .slide-count-4 {
  .slick-arrow {
    display: none;
  }
}

.slide-count-2, .slide-count-3, .slide-count-4 {
  .right-horizontal-level-2 {
    display: none;
  }
}

.slide-count-2, .slide-count-3 {
  .left-horizontal-level-2 {
    display: none;
  }
}

.slide-count-2 {
  .left-horizontal-level-1 {
    display: none;
  }
}

@media (max-width: $breakpoint-lg) {
  #carousel {
    .slide-container {
      margin: 0;
    }

    &.slide-count-1 {
      .slick-arrow {
        display: none;
      }
    }

    .slick-arrow {
      display: block;
      margin: 0 20px;
      position: absolute;
      top: initial;
      bottom: 50px;
    }

    .slide {
      &.left-horizontal-level-2, &.left-horizontal-level-1, &.right-horizontal-level-1, &.right-horizontal-level-2 {
        display: none;
      }
    }
  }
}
