@import "src/style/variable";
@import "src/style/mixins";

.moduleCardContainer {
  display: inline-block;
  background-color: $module-card-color;
  border-radius: $border-radius-md;
  padding: 30px 25px;
  transition: background-color 0.3s ease-in-out;

  &:global(.white) {
    background-color: $module-card-color-white;
  }
}

@media (max-width: $breakpoint-sm) {
  .moduleCardContainer {
    padding: 20px 15px;
  }
}
