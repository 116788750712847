@import "src/style/variable";
@import "src/style/mixins";

.componentContainer {
  .header {
    margin-bottom: $horizontal-spacing-lg;

    .topRow, .bottomRow {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        color: $theme-primary-light;
      }

      .addMandateButton {
        white-space: nowrap;
      }
    }

    .bottomRow {
      margin-top: $vertical-spacing-lg;

      .toggler {
        display: flex;
        align-items: center;
      }

      .searchContainer {
        display: flex;
        position: relative;
        width: 100%;
        overflow: hidden;

        *:first-child {
          margin-left: auto !important;
        }
      }
    }
  }
}

.switchLabel {
  font-size: $font-size-md;
  margin: 0 !important;
}

@media (max-width: $breakpoint-sm) {
  .componentContainer {
    .header {
      display: block;

      .topRow {
        display: block;

        .title {
          margin-bottom: $vertical-spacing-md;
        }
      }

      .bottomRow {
        position: relative;
        display: block;

        .toggler {
          float: right;
        }

        .searchContainer {
          padding-top: $vertical-spacing-md;
        }
      }
    }
  }
}
