@import "src/style/variable";
@import "src/style/mixins";

.cardButtonContainer {
  flex-direction: column;
  @include center-child();
  border-radius: $border-radius-md;
  cursor: pointer;
  position: relative;

  &:global(.large) {
    border: 3px solid $card-button-border-color;
    width: 150px;
    height: 192px;
    padding: 40px;


    .imageContainer {
      .image {
        width: 110px;
        height: 110px;
      }
    }

    .text {
      margin-top: 30px;
      line-height: 24px;
      text-transform: uppercase;
      text-align: center;
    }
  }

  &:global(.small) {
    width: 112px;
    height: 114px;

    .imageContainer {
      .image {
        width: 50px;
        height: 50px;
      }
    }

    .text {
      margin-top: 10px;
    }
  }

  &:global(.flex) {
    width: 100%;
    min-height: 100px;
    padding: 5px 4px;;

    .imageContainer {
      .image {
        width: 40px;
        height: 40px;
      }
    }

    .text {
      margin-top: 5px;
      min-height: 20px;
      word-break: break-word;
    }
  }

  &.filled {
    border: none;
    background-color: $card-background-color;
  }

  &:hover, &.isSelected {
    background-color: $card-background-colo-hover;

    &:global(.secondary) {
      background-color: $theme-secondary;
      border: 3px solid $card-button-border-color;
    }
  }

  &.isSelected:global(.secondary) {
    border: 3px solid transparent;
  }

  &:global(.very-light-green) {
    background-color: $card-button-very-light-green;
  }

  &:global(.lightest-green) {
    background-color: $card-button-lightest-green;
  }

  &:global(.flash-creme) {
    background-color: $card-button-flash-creme;
  }

  &:global(.dark-creme) {
    background-color: $card-button-dark-creme;
  }
}
