@import "src/style/variable";
@import "src/style/mixins";

.dropdownThumbnail {
  &:global(.MuiAccordion-root),
  &:global(.MuiAccordion-root:first-child),
  &:global(.MuiAccordion-root:last-child) {
    border-radius: $border-radius-xl;
  }

  &:global(.MuiAccordion-root) {
    box-shadow: none;
    background-color: $dropdown-thumbnail-body-background-color;

    &:global(.Mui-expanded) {
      margin: 0;
    }

    &:global(::before) {
      display: none;
    }
  }

  :global(.MuiAccordionSummary-root) {
    border-radius: $border-radius-xl;
    background-color: $dropdown-thumbnail-background-color;
    padding: 0 20px;

    :global(.MuiAccordionSummary-content) {
      position: relative;
      height: fit-content;

      &:global(.Mui-expanded) {
        margin: 12px 0;
      }
    }

    &:global(.Mui-expanded) {
      min-height: 58px;
      background-color: $dropdown-thumbnail-activate-background-color;
    }

    &:global(.Mui-focusVisible) {
      @include transparentBackgroundColorMixin(0.8, $dropdown-thumbnail-activate-background-color)
    }
  }

  :global(.MuiAccordionSummary-expandIconWrapper) {
    color: $dropdown-thumbnail-expand-icon-color;
    min-width: 20px;
    min-height: 20px;
    width: 20px;
    height: 20px;

    &:global(.Mui-expanded) {
      color: $dropdown-thumbnail-activate-expand-icon-color;
    }
  }

  :global(.MuiAccordionDetails-root) {
    padding: $vertical-spacing-md $vertical-spacing-md $vertical-spacing-lg;
  }

  .icon {
    padding-right: 20px;
  }

  .icons {
    padding-right: 10px;
    display: flex;
  }

  .iconElement {
    padding-right: 10px;
  }

  .title {
    word-break: break-word;
    color: $font-color-dark;
    font-weight: $font-semi-bold;
  }

  .iconContainer {
    margin-left: auto;
  }

  &.editMode {
    :global(.MuiAccordionSummary-expandIconWrapper) {
      display: none;
    }
  }

  .formContainer {
    width: 100%;
  }
}
