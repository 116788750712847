@import "src/style/variable";
@import "src/style/mixins";

.trialBoxContainer {
  background-color: $trial-background-color;
  margin-bottom: 75px;
  border-radius: $border-radius-lg;

  .trialBox {
    padding: 32px 26px;

    .closeButton {
      float: right;
    }
  }
}
