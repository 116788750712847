@import "src/style/variable";
@import "src/style/mixins";

.forgetPasswordButton {
  text-decoration: underline;
  cursor: pointer;
}

.resetPasswordDialog {
  :global(.MuiDialog-paper) {
    width: 400px;
  }

  .content {
    margin: 20px 0;

    .title {
      margin-bottom: 20px;
    }
  }

  .actions {
    margin: 30px 0 0;
  }
}
