@import "src/style/variable";

.circle {
  width: 100%;
  border-radius: 50%;
  border-width: 3px;
  border-style: solid;

  &:global(.primary) {
    border-color: $primary-circle-color;
  }

  &:global(.secondary) {
    border-color: $secondary-circle-color;
  }

  &.filled {
    &:global(.primary) {
      background-color: $primary-circle-color;
    }

    &:global(.secondary) {
      background-color: $secondary-circle-color;
    }
  }
}
