@import "src/style/variable";
@import "src/style/mixins";

.dialogMessage {
  :global(.MuiPaper-root) {
    flex-direction: row;
    width: fit-content;
    padding: 50px 50px 50px 50px !important;
    max-width: 80%;

    @media (max-width: $breakpoint-sm) {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }

  .image {
    height: 145px;
    width: 95px;
    padding: 0 50px 0 0;
    @media (max-width: $breakpoint-sm) {
      padding: 0 0 30px 0;
    }
  }

  .title {
    text-align: left;
    padding: 0 0 20px;
    text-transform: none;

    .closeButton {
      position: absolute;
      top: 20px;
      right: 20px;
      margin-left: 20px;
    }
  }

  .content {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    margin-right: 30px;
    margin-bottom: 20px;
  }

  .actions {
    justify-content: flex-start;

    @media (max-width: $breakpoint-sm) {
      justify-content: center;

      :nth-child(1) {
        height: fit-content;
        line-height: 18px;
      }
    }
  }
}
