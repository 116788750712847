@import "src/style/variable";
@import "src/style/mixins";

.fakeCheckboxButton {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: $white;
  height: 40px;
  border-radius: $border-radius-lg;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.98px;
  padding: 0 $vertical-spacing-md;

  &.checked {
    text-decoration: line-through;
  }
}
