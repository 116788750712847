@import "src/style/variable";
@import "src/style/mixins";

#TeamProfileBodyComponentContainer, #coacheeListing {
  background-color: $body-middle-color;
  border-radius: $border-radius-md;
  padding: $vertical-spacing-xl 0;

  .moduleContainer {
    @include center-child();
    padding: 30px;
  }

  .noteContainer, .linkedMandateList {
    padding: 50px 42px 0;
  }

  .noteContainer {
    padding-top: 0;
  }
}

#coacheeListing {
  text-align: center;
  min-height: fit-content;
  margin-top: $vertical-spacing-xl;
  margin-bottom: $vertical-spacing-xl;

  .moduleContainer {
    display: grid;
    gap: $vertical-spacing-lg;
  }
}

@media (max-width: $breakpoint-sm) {
  #TeamProfileBodyComponentContainer, #coacheeListing {
    .moduleContainer {
      padding: 0;
    }

    .noteContainer, .linkedMandateList {
      padding: 20px 12px;
    }
  }
}
