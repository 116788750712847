@import "src/style/variable";

:global(.flash) {
  .customizeAppBar {
    .navigationLogo {
      color: $theme-secondary-very-dark;

      &:active, &:focus, &:hover {
        color: $theme-secondary-very-dark;
      }
    }

    .buttonIcon {
      color: $theme-secondary-very-dark;

      &:hover, &:focus, &:active {
        background-color: rgba($theme-secondary-very-dark, 0.1);
      }
    }
  }

  .changeLanguage{
    color: $theme-secondary-very-dark;
  }
}

.customizeAppBar {
  .navigationLogo {
    margin-left: 5px;
    padding: 5px 20px;
    height: 50px;
  }

  &:global(.MuiAppBar-root) {
    position: absolute;
    box-shadow: none;
    color: $navbar-text-color;

    .container {
      padding: 0;

      :global(.MuiToolbar-root) {
        padding: 0;
      }
    }

    a {
      text-decoration-color: rgba($navbar-text-color, 0.6);
      color: $navbar-text-color;
      cursor: pointer;
      font: $font-size-lg;

      &:hover, &:focus, &:active {
        color: $navbar-text-color;
      }
    }

    .buttonIcon {
      padding: 9px;
      border-radius: 100%;
      transition: all .4s;

      &:hover {
        background-color: rgba($navbar-button-hover, 0.4);
        transition: all .4s;
      }
    }

    .search {
      svg {
        padding: 0;
        width: 30px;
      }
    }

    :global(.MuiMenuItem-root) {
      &:hover, &:focus, &:active {
        background: none;
      }

      &.buttonIcon {
        &:hover, &:focus, &:active {
          background-color: rgba($navbar-button-hover, 0.4);
        }
      }
    }

    :global(.MuiButtonBase-root) {
      &:hover, &:focus, &:active {
        background: none;
      }

      &.buttonIcon {
        &:hover, &:active, &.focus {
          background-color: rgba($navbar-button-hover, 0.4);
        }

        &.focus {
          color: $navbar-button-focus;
        }
      }
    }
  }

  .changeLanguage {
    text-decoration: underline;
  }
}

:global(.MuiMenu-paper) {
  box-shadow: none;
  margin-top: 0.5rem;

  .menuItemPopover {
    padding: 5px 10px;
    width: 100%;
    color: $font-color-dark;
    text-decoration: none;
  }
}
