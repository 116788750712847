@import "src/style/variable";
@import "src/style/mixins";

.tagsSection {
  margin-bottom: 20px;

  .tags {
    margin: 15px 0;
  }
}

.objectiveState {
  margin: 20px 0;
}

.subObjectiveHeader {
  position: relative;
  height: 40px;
  display: flex;
  margin-bottom: $vertical-spacing-md;

  .hideListedItemsButton {
    @include vertical-center();
    right: 0;
  }
}

.objectiveText {
  display: flex;
  flex-direction: column;
  gap: 10px;

  p {
    text-align: start;
  }
}

.input {
  margin-top: $vertical-spacing-md;
}
