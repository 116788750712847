@import "src/style/variable";
@import "src/style/mixins";

.container {
  display: flex;
  gap: $vertical-spacing-xxs;
  color: $white;
  border: 1px solid $white;
  padding: $vertical-spacing-xxs $horizontal-spacing-xs;
  border-radius: $border-radius-xl;
  line-height: 19px; /* 135.714% */
  letter-spacing: 0.98px;
  font-size: $font-size-sm;
}
