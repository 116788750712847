@import "src/style/variable";
@import "src/style/mixins";

.datePickerContainer {
  display: flex;
  gap: 10px;
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 300px;
  margin: 0 auto;

  .inputContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

:global(.Mui-selected) {
  &:focus {
    background-color: $theme-primary-middle !important;
  }

  &:hover {
    background-color: $theme-primary-middle !important;
  }
}

.datePicker {
  background-color: $white;
  border: 1px solid $theme-primary !important;
  border-radius: $border-radius-sm;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  transition: all 0.3s ease-in-out;
  width : 145px;

  ::before {
    transform: translateX(-20px) !important;
  }

  label {
    margin-top: 0.4rem;
  }

  button {
    color: $theme-secondary-dark !important;
    transition: background-color 0.3s ease-in-out;
  }

  &:hover, &:focus {
    border-color: $theme-primary-middle !important;

    button {
      background-color: $theme-primary-middle !important;
    }
  }
}
