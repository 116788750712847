@import "src/style/variable";
@import "src/style/mixins";

.container {
  position: fixed;
  bottom: 4rem;
  right: 1rem;
  z-index: 3;
  display: flex;
  align-items: center;
  gap: $horizontal-spacing-xs;
  background-color: $theme-primary-middle;
  padding: 10px 20px;
  border-radius: $border-radius-xl;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.12px;
  text-transform: uppercase;

  &:hover, &:focus {
    padding: 30px 40px;
    border-radius: 50px;
  }

  &:focus {
    background-color: $theme-primary;
  }
}
