@import "src/style/variable";
@import "src/style/mixins";

.trialContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 30px;
  margin: 30px 0;

  .image {
    width: 277px;
    text-align: center;
  }

  .text, .title {
    max-width: 600px;
  }

  .text {
    text-transform: none;
  }
}
