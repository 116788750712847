@import "src/style/variable";
@import "src/style/mixins";

.cardContainer {
  display: grid;
  color: $theme-secondary-very-dark;
  grid-gap: $horizontal-spacing-sm;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;

  @media (max-width: 1030px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 770px) {
    grid-template-columns: repeat(1, 1fr);
  }

  .cardParent {
    &.finished {
      > :first-child {
        background-color: $theme-secondary-very-light;
      }
    }

    > :first-child {
      width: calc(100% - 50px);
      min-height: 200px;
      height: fit-content;
    }

    &:hover {
      cursor: pointer;
    }

    &:hover > :first-child {
      background-color: $white;
    }
  }
}
