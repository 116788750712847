@import "src/style/variable";
@import "src/style/mixins";

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 375px;
  margin: 0 auto;

  .inputContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.listingContainer {
  display: flex;
  flex-direction: column;
  gap: $horizontal-spacing-sm;
  color: $theme-secondary-very-dark;
}

@media (max-width: $breakpoint-sm) {
  .formContainer {
    width: 100%;
  }
}