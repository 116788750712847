@import "src/style/variable";
@import "src/style/mixins";

[role="dialog"] {
  width: 450px;
}

:global(.MuiAccordion-root) {
  border-radius: 30px;
}

.dialog {
  margin: 100px auto;
  overflow-y: auto;


  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .deadline {
      display: flex;
      align-items: center;
      gap: $horizontal-spacing-xs;

      svg {
        height: 35px;
        width: 35px;
      }
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: $vertical-spacing-sm 3px;
  }

  .button {
    width: fit-content;
    margin: 0 auto;
  }
}
