@import "src/style/variable";
@import "src/style/mixins";

.date {
  font-size: $font-size-sm-md;
  font-weight: 700;
  letter-spacing: 1.05px;
  text-transform: uppercase;
}

.title {
  svg {
    height: 35px;
    width: 35px;
  }

  display: flex;
  align-items: center;
  gap: $horizontal-spacing-xs;
}

.deadline {
  display: flex;
  gap: $horizontal-spacing-xs;
  justify-content: space-between;
  align-items: center;
  text-align: left;

  .datePicker {
    max-width: 100%;
  }
}
