@import "src/style/variable";
@import "src/style/mixins";

.labels {
  font-size: $font-size-xs;
  color: $theme-primary-very-light;

  .lengthCount {
    text-align: right;
  }
}
