@import "src/style/variable";
@import "src/style/mixins";

.thumbnailProfileContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 220px;
  padding: 35px 20px;
  border-radius: $border-radius-md;
  background-color: $thumbnail-profile-background-color;
  cursor: pointer;
  text-transform: uppercase;

  .editIcon {
    display: none;
  }

  &.isSelected {
    transition: width 1s, background-color 1s;

    .editIcon {
      display: block;
      position: absolute;
      right: 0;
      margin-right: 20px;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .avatar {
      height: 78px;
      transition: height 1s 0.5s;
    }

    .enterprise {
      opacity: 1;
      max-height: 400px;
      transition: opacity 0.5s linear 0.5s, max-height 1s 0.5s;
    }

    .text {
      transition: font-size 1s;
    }
  }

  &.isNotSelected {
    background-color: $thumbnail-background-color;
    width: 125px;
    height: 100px;
    transition: width 0.5s, background-color 1s;

    &:hover {
      background-color: $thumbnail-background-color-hover;
    }

    .avatar {
      height: 60px;
      transition: height 1s 0.5s;
    }

    .enterprise {
      opacity: 0;
      max-height: 0;
      transition: opacity 0s, max-height 1s 0.5s;
    }

    .text {
      @include ellipsisMultiLine(2);
      font-weight: $font-semi-bold;
      font-size: $font-size-sm;
      color: $font-color-heavy-dark;
      padding-bottom: 35px;
    }
  }

  .circleContainer {
    width: 54px;
  }

  .text, .enterprise {
    text-align: center;
    margin-top: $vertical-spacing-md;
  }

  .enterprise {
    display: flex;
    align-items: center;

    img {
      margin-right: 10px;
    }

    .infos {
      display: flex;
      flex-direction: column;

      .companyName {
        margin: 0;
        white-space: pre-wrap;
        font-weight: $font-semi-bold;
        max-width: 165px;
      }
    }
  }
}
