@import "src/style/variable";
@import "src/style/mixins";

$base-sidebar-width: 550px;

.sidebar {
  position: absolute;
  right: 0;
  top: 0;
  border-radius: $border-radius-md;
  height: calc(100% - #{2 * $horizontal-spacing-md});
  width: calc(#{$base-sidebar-width} - #{2 * $horizontal-spacing-md});
  background-color: $white;
  color: $theme-secondary-dark;
  padding: $horizontal-spacing-md;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: -1;
  display: flex;
  flex-direction: column;
  gap: $vertical-spacing-sm;
  overflow-y: auto;
  scrollbar-width: thin;
}

.open {
  opacity: 1;
  z-index: 1;
}

@media (max-width: $breakpoint-sm) {
  .sidebar {
    position: fixed;
    width: 96.5vw;
    border-radius: 0;
    left: 0;
    height: calc(100% - #{2 * $vertical-spacing-md});
    padding: $horizontal-spacing-xs;
  }
  .open {
    z-index: 1100;
  }
}