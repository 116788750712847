$very-dark-green: #123029;
$dark-green: #20463D;
$middle-green: #315646;
$green: #49705D;
$light-green: #628269;
$very-light-green: #9AB59F;
$lightest-green: #C3D9B9;

$flash-cream: #FF8A00;
$dark-cream: #FFBF73;
$middle-cream: #FFDEB9;
$cream: #FFE8CE;
$light-cream: #FAF4EB;
$white: #FFFFFF;
$danger-zone-color: #FF4205;
$danger-zone-color-light: #FFC7B5;

$body-dark-color: $dark-green;
$body-middle-color: $green;
$body-light-color: $light-green;
$body-flash-color: $middle-cream;
$body-very-light-color: $very-light-green;

$footer-logo-color: $white;
$footer-text-color-flash-theme: $very-dark-green;
$footer-text-color: $white;
$footer-text-hover-color: $cream;
$footer-text-hover-color-flash-theme: $light-green;

$navbar-text-color: $middle-cream;
$navbar-text-color-focus: $dark-cream;
$navbar-button-hover: $dark-cream;
$navbar-button-focus: $flash-cream;
$navbar-popover-hover: $dark-cream;

$popover-separator-color: $middle-cream;

$theme-primary-dark: $flash-cream;
$theme-primary-middle: $dark-cream;
$theme-primary: $middle-cream;
$theme-primary-light: $cream;
$theme-primary-very-light: $light-cream;

$theme-secondary-very-dark: $very-dark-green;
$theme-secondary-dark: $dark-green;
$theme-secondary-middle: $middle-green;
$theme-secondary: $green;
$theme-secondary-light: $light-green;
$theme-secondary-very-light: $very-light-green;
$theme-transparent: transparent;

$theme-danger: $danger-zone-color;
$theme-danger-light: rgba($danger-zone-color, 0.1);

$form-section-background-color: $green;

$card-button-border-color: $dark-cream;
$card-button-text-color: $middle-cream;
$card-button-flash-creme : rgba($flash-cream, 0.4);
$card-button-dark-creme : rgba($dark-cream, 0.4);;
$card-button-very-light-green: $very-light-green;
$card-button-lightest-green: $lightest-green;
$card-button-overlay-hover: rgba($white, 0.3);

$thumbnail-background-color: $very-light-green;
$thumbnail-background-color-hover: $middle-cream;
$thumbnail-profile-background-color: $light-cream;

$card-background-color: $very-light-green;
$card-background-colo-hover: $middle-cream;
$card-body-primary-background-color: $middle-green;
$card-body-secondary-background-color: $cream;

$primary-circle-color: $middle-cream;
$secondary-circle-color: $dark-cream;
$progress-circle-background-color: $white;

$line-separator-color: $middle-cream;

$accordion-section-background-color: $green;
$accordion-summary-border-color: $middle-green;
$accordion-section-background-color-light: $light-cream;
$accordion-summary-border-color-light: $cream;

$slick-navigation-arrow-color: $light-cream;
$slick-navigation-arrow-color: $light-cream;
$slick-navigation-arrow-color-hover: $dark-cream;
$expand-icon-color: $light-cream;
$expand-icon-color-dark: $very-dark-green;

$title-record-text-color: $dark-green;

$input-select-arrow-down-color: $dark-green;
$input-select-text-color: $dark-green;
$input-select-no-option-text-color: $dark-green;
$input-selected-color: $middle-cream;
$input-focused-color: rgba($dark-cream, 0.4);
$input-select-icons-background-color: $dark-cream;
$input-select-icons-dropdown-background-color: $light-cream;

$module-card-color: $light-cream;
$module-card-creme-color: $cream;
$module-card-color-white: $white;

$dropdown-thumbnail-body-background-color: $cream;
$dropdown-thumbnail-background-color: $cream;
$dropdown-thumbnail-activate-background-color: $dark-cream;
$dropdown-thumbnail-expand-icon-color: $very-dark-green;
$dropdown-thumbnail-activate-expand-icon-color: $very-dark-green;

$input-error-color: $danger-zone-color;
$text-error-color: $danger-zone-color-light;

$alert-text-color: $dark-green;

$trial-background-color: $white;

$rich-text-editor-background-color: $white;
$rich-text-editor-toolbar-background-color: $white;
$rich-text-editor-border-color: $light-cream;
$rich-text-editor-icon-color: $green;
$rich-text-editor-icon-selected-color: $flash-cream;
$rich-text-editor-icon-hover-color: $very-dark-green;

$separator-color: $middle-cream;
$separator-hover-color: $light-cream;

:export {
  themeWhite: $white;
  themePrimaryDark: $theme-primary-dark;
  themePrimaryMiddle: $theme-primary-middle;
  themePrimary: $theme-primary;
  themePrimaryLight: $theme-primary-light;
  themePrimaryVeryLight: $theme-primary-very-light;

  themeSecondaryVeryDark: $theme-secondary-very-dark;
  themeSecondaryDark: $theme-secondary-dark;
  themeSecondaryMiddle: $theme-secondary-middle;
  themeSecondary: $theme-secondary;
  themeSecondaryLight: $theme-secondary-light;
  themeSecondaryVeryLight: $theme-secondary-very-light;

  themeTransparent: $theme-transparent;

  themeDanger: $theme-danger;
  themeDangerLight: $theme-danger-light;
}
