@import "src/style/variable";
@import "src/style/mixins";

$add-motivator-border-radius: $border-radius-xl;
$add-motivator-focused-outline: 1px solid $theme-primary-middle;
$input-background-color: $white;

fieldset {
  border: none !important;
}

.container {
  position: relative;

  &.isOnNewLine {
    display: contents;
  }

  &.isNotVisible {
    display: none;
  }

  .inputContainer {
    position: relative;
    height: 35px;
    border-radius: $add-motivator-border-radius;
    background-color: transparent;
    width: 100%;
    min-width: 35px;
    z-index: 2;
    transition: background-color 0.3s ease-in-out;
    outline: 1px solid transparent;

    &:global(.light).show {
      background-color: $white;
    }

    &:global(.dark).show {
      background-color: $theme-primary-very-light;
    }

    &:focus-within {
      outline: $add-motivator-focused-outline;
    }

    &.show {
      background-color: $input-background-color;
    }

    form {
      width: 100%;
      border-radius: $add-motivator-border-radius;

      div {
        width: 100%;
        background-color: transparent;
        border-radius: $add-motivator-border-radius;
        margin: 0;
      }
    }

    .addButton {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: absolute;
      left: 3px;
      top: 3px;
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
      transform: translate(-3px, -3px);

      &.show {
        opacity: 0;
      }
    }

    .input {
      opacity: 1;
      width: 100%;
      height: 34px;
      transition: opacity 0.3s ease-in-out;

      input {
        border-radius: $add-motivator-border-radius !important;
        width: 100% !important;
        background-color: transparent;
        padding: 6px 0 0 1.5rem;
      }

      &.show {
        opacity: 1;
      }
    }
  }
}
