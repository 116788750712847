@import "src/style/variable";
@import "src/style/mixins";

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.container {
  display: none;
  align-items: center;
  justify-content: space-between;
  gap: $horizontal-spacing-sm;
  width: 80%;

  svg {
    width: 30px;
    height: 30px;
  }

  &.show {
    display: flex;
  }

  .input {
    z-index: 10;


    &:global(.MuiTextField-root) {
      input {
        &[type="search"] {
          padding: 0 $horizontal-spacing-xs;
          transform: translateY(-2px);

        }
      }

      > * {
        border-radius: $border-radius-xl;
        border: 1px solid $theme-primary;
        box-shadow: none !important;
        height: 37px;

        &:focus, &:focus-within {
          background-color: $theme-primary-very-light;
        }
      }

      label {
        border: none;
        padding: 0 8px;

        &:focus, &:focus-within {
          display: none;
        }
      }

    }
  }
}
