@import "src/style/variable";

.listingContainer {
  display: flex;
  flex-flow: wrap;
  gap: 10px;

  svg {
    fill: $theme-secondary-very-dark;
  }

  .editButton {
    top: -5px;
  }
}
