@import "src/style/variable";
@import "src/style/mixins";

.container {
  background-color: $theme-primary-light;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: $horizontal-spacing-xs;
  border-radius: $border-radius-md;
  padding: $vertical-spacing-xs $horizontal-spacing-sm;
  width: 350px;

  img {
    width: 40px;
    height: 40px;
  }

  &:hover {
    background-color: $theme-primary-middle;
    cursor: pointer;
  }
}

@media (max-width: $breakpoint-sm) {
  .container {
    width: calc(100% - 30px);
  }
}
