@import "src/style/variable";
@import "src/style/mixins";

$gap: 20px;

@mixin arrow-position($top, $bottom, $left, $right, $rotate: 0) {
  display: block;
  top: $top;
  bottom: $bottom;
  left: $left;
  right: $right;
  transform: rotate($rotate);
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  height: 100%;
  width: 100%;

  &:global(.dark) {
    z-index: 1000;
    background-color: rgba(black, 0.35);
  }

  &:global(.blurred) {
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    z-index: 1000;
  }

  &.show {
    opacity: 1;
  }

  .closeButton {
    color: $theme-primary-dark;
    border-color: $theme-primary-dark;

    &:hover, &:focus {
      color: $white;
      background-color: $theme-primary-dark;
    }
  }

  .container {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    gap: $gap;
    height: fit-content;
    min-height: 100px;
    width: 500px;
    background-color: $white;
    border-radius: $border-radius-md;
    z-index: 2001;

    .image {
      min-width: 100px;
      width: 100%;
      max-width: 500px;
      align-self: center;
      border-radius: $border-radius-sm;
      margin-bottom: $vertical-spacing-md;
    }

    .innerContent {
      @include center-child;
      flex-direction: column;
      gap: $gap;
      width: 100%;

      .text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: $gap;
        text-align: left;
        width: 100%;
      }

      .buttons {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: $gap;
      }
    }
  }

  .xIcon {
    position: absolute;
    z-index: 100;
    top: 10px;
    right: 10px;
  }
}

.arrow {
  position: absolute;
  width: 20px;
  height: 20px;
  z-index: 5;
  display: none;

  &:global(.top-left) {
    @include arrow-position(-20px, null, 20px, null);
  }

  &:global(.top-right) {
    @include arrow-position(-20px, null, null, 35px);
  }

  &:global(.top-center) {
    @include arrow-position(-20px, 50%, null, null);
  }

  &:global(.bottom-left) {
    @include arrow-position(null, -20px, 40px, null, 180deg);
  }

  &:global(.bottom-right) {
    @include arrow-position(null, -20px, null, 20px, 180deg);
  }

  &:global(.bottom-center) {
    @include arrow-position(null, -20px, 50%, null, 180deg);
  }

  &:global(.left-top) {
    @include arrow-position(40px, null, -20px, null, 270deg);
  }

  &:global(.left-bottom) {
    @include arrow-position(null, 20px, -20px, null, 270deg);
  }

  &:global(.left-center) {
    @include arrow-position(50%, null, -20px, null, 270deg);
  }

  &:global(.right-top) {
    @include arrow-position(20px, null, null, -20px, 90deg);
  }

  &:global(.right-bottom) {
    @include arrow-position(null, 40px, null, -20px, 90deg);
  }

  &:global(.right-center) {
    @include arrow-position(null, 50%, null, -20px, 90deg);
  }
}
