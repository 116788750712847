@import "src/style/variable";


.container {
  border-radius: $border-radius-md;
  background-color: $light-cream;
  width: 700px;
  display: flex;
  flex-direction: column;
}

.title {
  margin: 20px auto;
}

.controls {
  display: flex;
  flex-direction: row;
  height: 30px;
  width: 100%;
  justify-content: space-between;

  .intervalNavigation {
    display: flex;
    width: 40%;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    .arrowIcon {
      width: 15%;
      height: 15px;
      vertical-align: center;
      cursor: pointer;
    }

    .intervalText {
      width: 110px
    }
  }

  .intervalSelection {
    padding: 0 10px;
    display: flex;
    width: 35%;
    flex-direction: row;
    justify-content: space-between;
  }
}

.mainTable {
  padding: 15px;

  .verticalLine {
    horiz-align: left;
    height: 160px;
    border-left: 1px solid black;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .row {
      padding: 5px;
      display: flex;
      flex-direction: row;

      .tableCell {
        flex: 1;
        bottom: 0;
        align-content: center;
        width: 20px;
        height: 20px;
      }
    }
  }

  .timeInterval {
    padding: 5px;
    display: flex;
    flex-direction: row;

    .tableCell {
      flex: 1;
      bottom: 0;
      align-items: center;
    }
  }
}

.commentSection {
  display: flex;
  margin: 0 15px;
  flex-direction: column;

  .selectedState {
    display: inline-flex;
    align-items: center;

    .selectedStateText {
      margin: 0 15px;
    }
  }

  .commentList {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    overflow-x: hidden;
    max-height: 140px;

    .commentContainer {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 10px;
      padding: 5px 10px;

      .chatBubble {
        padding-top: 3px;
      }

      .individualComment {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .comment {
          width: 100%;
        }
      }
    }

  }
}

.tableCellChatBubble {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.tableEndCellChatBubble {
  display: flex;
  align-items: center;
  padding-left: 1px;
}

.commentSectionLoader {
  margin: 10px 15px;
}

.tableCellClickable {
  cursor: pointer;
}

.notSelected {
  opacity: 0.4;
}
