@import "src/style/variable";
@import "src/style/mixins";

.updateTeamFormComponent {
  display: grid;
  gap: 20px;

  * > p {
    margin-bottom: 10px;
  }

  :global(.MuiFormControl-root) {
    width: 100%;
  }
}
