@import "src/style/variable";
@import "src/style/mixins";

.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: $vertical-spacing-xs;
  padding: 9px 20px;
  border-radius: $border-radius-sm;

  &:global(.white) {
    background-color: $white;
  }

  &:global(.outline) {
    background-color: $white;
    border: 1px solid $theme-primary-middle;
  }

  &:global(.small) {
    width: auto;
  }

  &:global(.medium) {
    width: 430px;
  }

  &:global(.large) {
    width: 100%;
  }

  .input {
    width: 100%;
    border: none;
    outline: none;
    font-size: $font-size-sm;

    input::placeholder {
      font-size: $font-size-md;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 1.12px;
    }
  }
}

