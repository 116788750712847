@import "src/style/variable";
@import "src/style/mixins";

#backgroundTheme {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

:global(.textured) {
  #backgroundTheme {
    &::before {
      @include generatedBackground();
    }
  }
}

:global(.dark) {
  #backgroundTheme {
    background-color: $body-dark-color;
  }
}

:global(.light) {
  #backgroundTheme {
    background-color: $body-light-color;
  }
}

:global(.flash) {
  #backgroundTheme {
    background-color: $body-flash-color;
  }
}
