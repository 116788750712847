@import "src/style/variable";
@import "src/style/mixins";

.quill-container {
  .ql-container {
    height: 300px;
    overflow: scroll;

    border-bottom-left-radius: $border-radius-sm;
    border-bottom-right-radius: $border-radius-sm;
    border-color: $rich-text-editor-border-color;
    background: $rich-text-editor-background-color;
  }

  .ql-snow.ql-toolbar {
    background: $rich-text-editor-toolbar-background-color;
    border-color: $rich-text-editor-border-color;
    border-top-left-radius: $border-radius-sm;
    border-top-right-radius: $border-radius-sm;
  }

  .ql-editor {
    min-height: 18em;
    font-family: $font-family;
  }

  .ql-toolbar {
    .ql-stroke {
      fill: none;
      stroke: $rich-text-editor-icon-color;
    }

    .ql-fill {
      fill: $rich-text-editor-icon-color;
      stroke: none;
    }

    .ql-picker {
      color: $rich-text-editor-icon-color;
    }

    button:hover .ql-stroke,
    .ql-picker-label:hover .ql-stroke,
    button:hover .ql-fill,
    .ql-picker-label:hover .ql-fill {
      stroke: $rich-text-editor-icon-hover-color;
    }

    button.ql-active .ql-stroke,
    .ql-picker-label.ql-active .ql-stroke {
      stroke: $rich-text-editor-icon-selected-color;
    }

    button:hover .ql-fill,
    .ql-picker-label:hover .ql-fill {
      fill: $rich-text-editor-icon-hover-color;
    }

    button.ql-active .ql-fill {
      fill: $rich-text-editor-icon-selected-color;
    }

    .ql-header.ql-picker .ql-picker-label.ql-active {
      color: $rich-text-editor-icon-selected-color;
    }

    .ql-picker-options .ql-picker-item:hover {
      color: $rich-text-editor-icon-hover-color;
    }
  }
}
