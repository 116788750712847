@import "src/style/variable";
@import "src/style/mixins";

.tagSelector {
  @include horizontal-center();
  background-color: $module-card-color-white;
  position: absolute;
  z-index: 4;
  width: 300px;
  display: flex;
  flex-flow: wrap;
  padding: 15px;
  gap: 12px;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.20);
  border-radius: $border-radius-sm;
  margin-top: 5px;
}
