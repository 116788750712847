@import "src/style/variable";
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import "src/style/mixins";

.slider {
  overflow: hidden;
  width: 992px;

  &.active-slider {
    margin: 0 auto;
  }

  &.not-active-slider {
    margin: 0;
  }

  .slick-slider {
    .slick-arrow {
      top: 80px;
    }

    .slick-disabled {
      opacity: 0.5;

      &:hover {
        color: $slick-navigation-arrow-color;
      }
    }

    padding: 0 124px;

    .slick-slide {
      margin: 0 10px;
    }

    .slick-list {
      padding-left: 15px;
    }

    &.align-left {
      padding-left: 0;
      padding-right: 0;
      margin-left: initial;
      margin-right: initial;

      .slick-list {
        padding: 0;

        .slick-track {
          display: flex;
          margin-left: initial;
          margin-right: initial;

          .slick-slide {
            margin: 0 20px;

            &:first-child {
              margin: 0 20px 0 0;
            }
          }
        }
      }
    }

    .slick-track {
      display: flex;
    }
  }

  @media (max-width: $breakpoint-lg) {
    width: 100%;
    overflow: initial;

    .slick-slider {
      padding: 0;
      position: relative;

      .slick-list {
        display: contents;
        padding: 0;

        .slick-track {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          transform: none !important;
          row-gap: 20px;

          .slick-slide {
            margin: 10px;
          }
        }
      }
    }

    .slick-arrow {
      display: none;
    }
  }
}
