@import "src/style/variable";
@import "src/style/mixins";

.container {
  border-radius : $border-radius-md;
  padding : 15px 11px 2px;
  margin: 15px 0 15px;

  &:global(.very-light-green) {
    background-color: $card-button-very-light-green;
  }

  &:global(.lightest-green) {
    background-color: $card-button-lightest-green;
  }

  &:global(.flash-creme) {
    background-color: $card-button-flash-creme;
  }

  &:global(.dark-creme) {
    background-color: $card-button-dark-creme;
  }
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  margin: 0 0 10px;

  .closeIcon {
    right: 0;
    top: 0;
    position: absolute;
  }
}


