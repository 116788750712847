@import "src/style/variable";
@import "src/style/mixins";

.separatorLine {
  width: 100%;

  &:global(.white) {
    @include lineSeparator($white);
  }

  &:global(.black) {
    @include lineSeparator(black);
  }

  &:global(.middle-cream) {
    @include lineSeparator($middle-cream);
  }
}
