@import "src/style/variable";
@import "src/style/mixins";

.teamNeedContainer {
  position: relative;
  width: 100%;
  height: 60px;

  .teamNeed {
    position: relative;
    cursor: pointer;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    z-index: 1;

    &.filled {
      &:after {
        background-color: $secondary-circle-color;
      }
    }

    &.done {
      .text {
        margin-top: -12px;
      }

      &:after {
        background-color: $very-light-green;
      }
    }

    .text {
      @include vertical-center();
      @include horizontal-center();
      text-transform: uppercase;
      z-index: 2;
      margin-top: -8px;

      svg {
        margin-left: 10px;
        transform: translateY(3px) scale(1.1);
        fill: $white;
      }
    }

    &:after {
      position: absolute;
      background-color: $primary-circle-color;
      content: '';
      height: 100%;
      left: 0;
    }

    &:hover {
      &:after {
        background-color: $secondary-circle-color;
      }
    }

    &:global(.RESULT) {
      .text {
        margin-top: -4px;
      }

      width: 60px;

      &::after {
        @include pyramidStage(60px, 0);
      }

      &.done {
        .text {
          width: 130px;
          transform: translateX(-45px);

          svg {
            fill: $dark-green;
          }
        }
      }
    }

    &:global(.RESPONSIBILITY) {
      width: 130px;

      &::after {
        @include pyramidStage(130px, -100%);
      }

      &.done {
        .text {
          width: 180px;
          transform: translateX(-75px);

          svg {
            fill: $dark-green;
          }
        }
      }
    }

    &:global(.ENGAGEMENT) {
      width: 205px;

      &::after {
        @include pyramidStage(205px, -200%);
      }

      &.done {
        .text {
          width: 180px;
          transform: translateX(-65px);
        }
      }
    }

    &:global(.CONFRONTATION) {
      width: 280px;

      &::after {
        @include pyramidStage(280px, -300%);
      }

      &.done {
        .text {
          width: 180px;
          transform: translateX(-65px);
        }
      }
    }

    &:global(.TRUST) {
      width: 360px;

      &::after {
        @include pyramidStage(360px, -400%);
      }

      &.done {
        .text {
          transform: translateX(-55px);
        }
      }
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .teamNeedContainer {
    .teamNeed {
      &:global(.RESULT) {
        width: 35px;

        &::after {
          @include pyramidStage(35px, 0);
        }

        .text {
          margin-top: -4px;
        }
      }

      &:global(.RESPONSIBILITY) {
        width: 85px;

        &::after {
          @include pyramidStage(85px, -100%);
        }
      }

      &:global(.ENGAGEMENT) {
        width: 140px;

        &::after {
          @include pyramidStage(140px, -200%);
        }
      }

      &:global(.CONFRONTATION) {
        width: 195px;

        &::after {
          @include pyramidStage(195px, -300%);
        }
      }

      &:global(.TRUST) {
        width: 250px;

        &::after {
          @include pyramidStage(250px, -400%);
        }
      }
    }
  }
}
