@import "src/style/variable";
@import "src/style/mixins";

#subscriptionCard {
  background-color: white;
  padding: $vertical-spacing-lg $horizontal-spacing-sm;
  border-radius: $border-radius-sm;

  #header {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: $vertical-spacing-lg;

    @media (max-width: $breakpoint-md) {
      flex-direction: column;
      gap: 20px;
    }

    .subscriptionStatus {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;
    }

    &:before {
      content: '';
      position: absolute;
      display: block;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-bottom: 1px solid $card-body-secondary-background-color;
    }
  }

  #footer {
    @include center-child();
    padding-top: $vertical-spacing-lg;
    display: flex;
    justify-content: space-between;

    .card {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 45px;
      border-radius: $border-radius-sm;
      border: 1px solid $card-body-secondary-background-color;
      padding: 15px;

      .text {
        :global(.MuiTypography-root) {
          display: block;

          &:first-child {
            margin-bottom: 8px;
          }
        }
      }
    }

    .paddle {
      text-align: center;

      :global(.MuiTypography-root) {
        display: block;

        a {
          text-decoration-color: rgba($theme-secondary-very-dark, 0.6);
          color: $theme-secondary-very-dark;
          cursor: pointer;
        }
      }
    }

    &.singleButton {
      justify-content: right;
      display: block;
      width: 100%;
    }

    .errorIcon {
      position: relative;
      top: 6px;
      margin-right: 8px;
    }
  }
}
