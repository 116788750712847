@import "src/style/mixins";
@import "src/style/variable";

#loginPage {
  position: fixed;
  height: 100%;
  width: 100%;
  @include center-child();

  #boxContainer {
    display: flex;
    flex-direction: column;
    align-items: center
  }

  &::before {
    @include backgroundCover();
    background-image: $texture-background-image-dark-green-degraded;
  }

  .formTitle {
    letter-spacing: 0.1em;
    margin: 45px 0 20px;
    position: relative;
  }

  #logo {
    z-index: 1;
  }

  .registerButton {
    position: absolute;
    top: 0;
    right: 0;
    margin: $horizontal-spacing-md $vertical-spacing-lg;
  }
}

@media (max-width: $breakpoint-md) {
  #loginPage {
    position: relative;
    padding: 120px 0;

    #logo {
      height: 200px;
    }
  }
}
