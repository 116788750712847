@import "src/style/variable";
@import "src/style/mixins";

#TeamProfileHeaderComponentContainer {
  padding: $vertical-spacing-lg 0;
  height: 350px;

  :global(.slick-arrow) {
    top: 50px;
  }
}
