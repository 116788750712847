@import "src/style/variable";
@import "src/style/mixins";

#CoacheeProfileHeaderComponentContainer {
  padding: $vertical-spacing-lg 0;
  margin-bottom: -80px;
  height: 350px;

  @media (max-width: $breakpoint-lg) {
    margin-bottom: 0;
  }
}
