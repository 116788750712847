@import "src/style/variable";
@import "src/style/mixins";

.done {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $horizontal-spacing-xs;

  &.clickable {
    cursor: pointer;
  }

  color: $theme-secondary-very-dark;

  &:hover {
    color: $theme-secondary;

    svg path {
      fill: $theme-secondary;
    }
  }
}
