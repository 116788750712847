@import "src/style/variable";
@import "src/style/mixins";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;

  @media (max-width: $breakpoint-sm) {
    display: contents;

    > * {
      align-self: center;
    }
  }
}
