@import "src/style/variable";
@import "src/style/mixins";

.updateDialog {
  :global(.MuiPaper-root) {
    width: 500px;
  }

  .title {
    display: flex;
    padding: 0;
    float: right;

    .closeButton {
      margin-left: 20px;
    }
  }

  .content {
    margin: 50px 0 40px;
  }

  .actions {
    margin: 0;
  }
}
